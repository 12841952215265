@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --color-cream: #f9fbea;
}

body {
  @apply font-mono;
  background-color: var(--color-cream);
}
nav ul {
  list-style: none;
  padding: 0;
  display: flex;
  gap: 20px;
}
